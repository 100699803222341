import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
        nombreApellido: Yup
            .string()
            .required("Este campo es requerido"),       
        fechaNacimiento: Yup
            .string()
            .required("Este campo es requerido"),
        domicilio: Yup
            .string(),
        telefono: Yup
            .string()
            .matches(/^\d+$/, "Ingrese sólo carácteres númericos")
            .required("Este campo es requerido"),
        email: Yup
            .string()
            .email("Debe ser un email válido")
            .required("Este campo es requerido"),
        comentarios: Yup
            .string()
            .max(5000, "Excede la cantidad máxima de carácteres"),
        archivos: Yup
            .string()
            .required("Este campo es requerido")
    })