import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { MenuBarra } from './menuBarra/menuBarra';
import { Link } from 'react-router-dom';
import "./Barra.css";
import Logo from '../../assets/logos/logo_eiv_color_sep.svg';


const pages = [
    { id: 1, nombre: 'HOME', ref: 'home'},
    { id: 2, nombre: 'PRODUCTOS', ref: 'productos' },
    { id: 3, nombre: 'EMPRESA', ref: 'empresas' },
    { id: 4, nombre: 'CLIENTES', ref: 'clientes' },
    { id: 5, nombre: 'CONTACTO', ref: 'contacto'},
    { id: 6, nombre: 'TRABAJA CON NOSOTROS', ref: 'rrhh' }
];

/* const settings = ['INGRESO CLIENTES',]; */

const Barra = () => {
    const [anchorEl, setAnchorEl] = React.useState({
        navbar: null,
        rrhh: null
    })

    const handleOpenAnchor = (event, target) => {
        setAnchorEl({...anchorEl, [target]: event.currentTarget})
    }

    const handleCloseAnchor = (target) => {
        setAnchorEl({...anchorEl, [target]: null})
    }

    return (
        <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
            <Container maxWidth="xl" sx={{ marginLeft:'0px !important'  }}>
                <Toolbar disableGutters >
                    {/* LOGO */}
                    <Typography
                        noWrap
                        component="div"
                        sx={{ m: 'auto', alignContent:'left', display: { xs: 'none', lg: 'flex' } }}
                    >
                        <Link to={{pathname: "/" }} state={'home'}>
                            <img  src={Logo} alt="logo" />
                        </Link>
                    </Typography>
                    
                    {/* BOTONES XS */}
                    <MenuBarra 
                        handleOpenAnchor={handleOpenAnchor}
                        handleCloseAnchor={handleCloseAnchor}
                        anchorEl={anchorEl?.navbar}
                        />
            
                    {/* BOTONES MD */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
                        {pages.map(page => {
                            return (
                                <div key={page.id}>
                                    { page.ref === 'rrhh' ? 
                                        <Link className="link" to="/rrhh">
                                            <Button
                                                key={page.id}
                                                sx={{ my: 2, display: 'block', color: '#36a9df !important' }}
                                            >
                                                {page.nombre}
                                            </Button>
                                        </Link> :
                                        <Link className="link" to={{ pathname: `/`, hash:`${page.ref}` }} state={`${page.ref}`} >
                                            <Button
                                                key={page.id}
                                                sx={{ my: 2, display: 'block', color: '#36a9df !important' }}
                                            >
                                                {page.nombre}
                                            </Button>
                                        </Link>
                                        
                                    }
                                </div>
                            )
                        })}
                    </Box>
                    
                    {/* ACCESO A CLIENTES */}
                   {/*  <Box sx={{ flexGrow: 0, display: { xs: 'none', lg: 'flex' }, justifyContent: 'right' }}>
                        <Tooltip title="En construccion">
                            <Button color="info" variant='outlined' >Acceso clientes</Button>
                        </Tooltip>
                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Barra