import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import Curriculum from '../../components/forms/curriculumForm/Curriculum'
import FondoI from '../../assets/backgrounds/img_contenido_redesc.png';
import { ComponentContainer } from '../../components/container/componentContainer'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { BusquedasComponent } from '../../components/busquedas/Busquedas';
import "./Rrhh.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Rrhh = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>EIV Software | RRHH</title>
                    {/*
                    <link rel="canonical" href="https://eiva.com.ar/rrhh" />
                    <link rel="canonical" href="https://eivsofware.com.ar/rrhh" />
                    <link rel="canonical" href="https://eivsofware.com/rrhh" />
                    */}
                    <meta name="description" content="¿Querés formar parte del equipo de EIV Software? En EIV creemos en el trabajo en equipo como motor de motivación, compromiso y nuevas ideas. Sumate a EIV. "/>
                    <meta property='og:type' content="website"/>
                    <meta property='og:title' content="EIV Software | RRHH"/>
                    <meta property='og:url' content="https://eiva.com.ar/rrhh"/>
                </Helmet>
            </HelmetProvider>
            <ComponentContainer bgImg={FondoI}>
                <AnimationOnScroll
                    animateIn='fadeIn'
                    animateOut='fadeOut'
                    duration={1}
                    delay={0.5}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        flexWrap: "wrap",
                        columnGap: 3, 
                        pt: 2,
                        rowGap: 3,
                        alignItems: 'start', 
                        textAlign: 'left', 
                        width: '100%'
                    }}>
                        <div className="busquedas-container">
                            <Typography
                                variant="h4"
                                component="div"
                                align="left"
                                sx={{
                                    flexGrow: 1,
                                    fontFamily: 'Montserrat',
                                    fontWeight: 'bold'
                                }}>
                                TRABAJA CON NOSOTROS
                            </Typography>

                            <Box sx={{
                                textAlign: 'left',
                                borderLeft: 10,
                                borderColor: 'text.primary',
                                p: 2
                            }}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                    sx={{
                                        flexGrow: 1,
                                        fontFamily: 'Montserrat',
                                        lineHeight: 1,
                                    }}>
                                    <Box sx={{
                                        paddingLeft:"16px"
                                    }}>
                                        <b>¿QUERÉS SER PARTE DE NUESTRO EQUIPO?</b>
                                        <p />
                                        <span style={{fontSize: '1.1rem'}}>
                                            EIV Software S.R.L. es una empresa de desarrollo de software orientada a entidades financieras.
                                            En EIV creemos en el trabajo en equipo, como motor de mayor motivacion, compromiso, nuevas ideas y mejores resultados.
                                            Es por eso que consideramos sumarte a nuestro equipo.
                                        </span>
                                    </Box>
                                </Typography>
                               
                            </Box>
                            <BusquedasComponent />
                        </div>
                        <Curriculum
                            titulo="DEJANOS TU CURRICULUM"
                        />
                    </Box>
                </AnimationOnScroll>
            </ComponentContainer>
        </>
    )
}

export default Rrhh
