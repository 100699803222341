import { Alert } from "@mui/material";
import { useState, useEffect } from "react";

export const AlertComponent = ({response, error}) => {
    const [alert, setAlert] = useState({show: false, type: 'success', message: ''})
    
    useEffect(() => {
        if(response.status === 200) {
            setAlert({show: true, type: 'success', message: 'El mail ha sido enviado correctamente. Alguien de nuestro equipo se pondrá en contacto con usted.'})
            setTimeout(function() {
                setAlert({show: false, type: 'success', message: ''})
            }, 7000)
        } else if(error) {
            setAlert({show: true, type: 'error', message: 'Se produjo un error al enviar el mail, vuelva a intentar en otro momento'})
            setTimeout(function() {
                setAlert({show: false, type: 'success', message: ''})
            }, 7000)
        }
    }, [response, error])
    
    return alert.show ? <Alert variant="standard" severity={alert.type}>{alert.message}</Alert> : null
}