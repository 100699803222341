import { Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import NavigationIcon from '@mui/icons-material/Navigation'
import "./ButtonComponent.css"

export const ButtonComponent = ({type, name, text, htmlFor, inputFile, icon, change}) => {
    let IconComponent = <></>;

    switch(icon) {
        case 'add':
            IconComponent = AddIcon;
            break;
        case 'nav':
            IconComponent = NavigationIcon;
            break;
        default:
            IconComponent = <></>;
            break;
    }
    return (
        <label align="center" htmlFor={htmlFor}>
            {inputFile ? <input onChange={(e) => change(e)} type="file" id={htmlFor} style={{ display: 'none' }}></input> : ''}
            <Box 
                href={name}
                className="button-send"
                type={type ? 'submit': ''}
                component={type? 'button': 'span'}
                sx={{ m: 2, minWidth:"200px" }} >
                <IconComponent />
                {text}
            </Box>
        </label>
    )
}