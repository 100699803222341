import ReCaptchaV2 from "react-google-recaptcha";

export const Captcha = ({setCaptcha}) => {

    let config = window['siteConfig'];

    const handleChange = (captcha) => {
        setCaptcha(captcha);
    }

    const handleExpire = () => {
        setCaptcha(null);
    }

    return <ReCaptchaV2 sitekey={config.recaptcha.sitekey} onChange={handleChange} onExpired={handleExpire}/>

}