import { Box, Typography } from '@mui/material'
import React from 'react'
import { FormComponent } from './formComponent/FormComponent';
import './Curriculum.css'
const Curriculum = ({ titulo }) => {
    const day = (new Date()).getDate();
    const month = (new Date()).getMonth() + 1;
    const year = (new Date()).getFullYear();
    const date = `${year}-0${month}-0${day}`;

    const initialForm = {
        nombreApellido: {
            value: '',
            label: 'Nombre y apellido',
            errors: { exists: false, message: ''},
            required: true,
        },
        fechaNacimiento: {
            value: date,
            label: 'Fecha de Nacimiento',
            errors: { exists: false, message: ''},
            type: 'date',
            required: true,
            inputProps: {'min': "1800-01-01", 'max':`${date}`}
        },
        domicilio: {
            value: '',
            label: 'Domicilio',
            errors: { exists: false, message: ''},
        },
        telefono: {
            value: '',
            label: 'Teléfono',
            errors: { exists: false, message: ''},
            type: 'number',
            required: true,
        },
        email: {
            value: '',
            label: 'Email',
            type: 'mail',
            errors: { exists: false, message: ''},
            required: true,
        },
        comentarios: {
            value: '',
            label: 'Comentarios',
            errors: { exists: false, message: ''},
            multiline: true,
        },
        archivos: {
            value: '',
            errors: { exists: false, message: ''},
            name: '',
            type: 'file',
            label: '',
        }
    }

    return (
        <>
            <Box
                className="curriculum-container"
                component="div"
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    width: {sm: "80%", md: "40%", maxWidth: '500px'},
                    '& .MuiTextField-root': {pr: 1, pl: 1, width: '100%', borderTopLeftRadius:10, borderTopRightRadius:10 },
                    '& .MuiInputLabel-root': { fontWeight: '1rem', borderTopLeftRadius:10, borderTopRightRadius:10 },
                }}>
                <Typography
                    component="div"
                    align="center"
                    variant="h4"
                    sx={{
                        flexGrow: 1,
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                    }} >
                    {titulo}
                </Typography>  
                <FormComponent initialForm={initialForm}></FormComponent>                                     
            </Box>
        </>
    )
}

export default Curriculum
