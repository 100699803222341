import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {AccordionDetails, Accordion, Typography, Box, AccordionSummary } from "@mui/material"
import "./Busquedas.css"

export const BusquedasComponent = () => {
    return(
        <>
            <Typography
               variant="h4"
               component="div"
               align="left"
               sx={{
                   flexGrow: 1,
                   fontFamily: 'Montserrat',
                   fontWeight: 'bold'
               }}>
                BUSQUEDAS ACTIVAS
            </Typography>
            <Box sx={{ width: "100%" }}>
                <Accordion defaultExpanded elevation={0}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<KeyboardArrowDownIcon sx={{fontSize: '2em',color: "#fff"}}></KeyboardArrowDownIcon>}>
                        <Typography
                            variant="h6"
                            sx={{fontWeight: "bold"}}
                        >
                            DESARROLLADOR JAVA
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-content" sx={{ maxHeight: '350px', overflow: "auto",}}>
                        <div className="requisitos-container">
                            <Typography
                                variant="h6"
                                sx={{fontWeight: "bold", fontSize: '1.1em'}}
                            >
                                REQUISITOS EXCLUYENTES
                            </Typography>
                            <Typography
                                variant="p"
                                fontFamily={'Montserrat'}
                            >
                                <div className="requisitos-content">
                                    Backend java o .net developer con +3 años de experiencia. 
                                    <br />
                                    Conocimientos de bases de datos relacionales y transact-sql.
                                    <br /> 
                                    Experiencia y/o conocimientos consumiendo y desarrollando servicios rest y/o soap. 
                                    <br />
                                    Buen manejo de herramientas de versionado de codigo / git. 
                                    <br />
                                    Nivel de ingles: intermedio
                                </div>
                                
                            </Typography>
                        </div>
                        <div className="requisitos-container">
                            <Typography
                                variant="h6"
                                sx={{fontWeight: "bold", fontSize: '1.1em'}}
                            >
                                REQUISITOS DESEABLES
                            </Typography>
                            <Typography
                                variant="p"
                                fontFamily={'Montserrat'}
                            >
                                <div className="requisitos-content">
                                    TDD e integración continua. 
                                    <br />
                                    Revisión de código, ajuste de performance, cuellos de botella.
                                    <br />
                                    Patrones de diseño y arquitectura, event-driven architecture y soa. 
                                    <br />
                                    Experiencia en rubro de seguros. Experiencia en prácticas agile, metodologia scrum.
                                </div>
                            </Typography>
                        </div>
                        <div className="requisitos-container">
                            <Typography
                                variant="h6"
                                sx={{fontWeight: "bold", fontSize: '1.1em'}}
                            >
                                OFRECEMOS
                            </Typography>
                            <Typography
                                variant="p"
                                fontFamily={'Montserrat'}
                            >
                                <div className="requisitos-content">
                                    Bono anual por cumplimiento de objetivos.
                                    <br />
                                    Acceso a plataforma de capacitacion online.
                                    <br />
                                    Beneficios para tu salud y bienestar.
                                    <br />
                                    Gift card con importantes beneficios.
                                    <br />
                                    Flexibilidad para trabajo en nuestras oficinas o trabajo remoto.
                                </div>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    )
}