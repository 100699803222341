import { Box, IconButton, Menu, MenuItem, Typography} from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import "./menuBarra.css"
import Logo from '../../../assets/logos/logo_eiv_color_sep.svg'

const pages = [
    { id: 1, nombre: 'HOME', ref: 'home'},
    { id: 2, nombre: 'PRODUCTOS', ref: 'productos' },
    { id: 3, nombre: 'EMPRESA', ref: 'empresas' },
    { id: 4, nombre: 'CLIENTES', ref: 'clientes' },
    { id: 5, nombre: 'CONTACTO', ref: 'contacto'},
    { id: 6, nombre: 'TRABAJA CON NOSOTROS', ref: 'rrhh' }
];

export const MenuBarra = ({handleOpenAnchor, anchorEl, handleCloseAnchor}) => {
    return(
        <>
            <Box sx={{ width:'100%', align: 'center', justifyContent: 'space-between', flexGrow: 0, display: { xs: 'flex', lg: 'none' }}}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(e) => handleOpenAnchor(e, 'navbar')}
                >
                    {anchorEl ? <CloseIcon /> : <MenuIcon />}
                </IconButton>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                    open={Boolean(anchorEl)}
                    onClose={() => handleCloseAnchor('navbar')}
                    sx={{ m: 'auto', display: { xs: 'block', lg: 'none' }, }}
                >
                    {pages.map(page => {
                        return (
                            <div key={page.id}>
                                {page.ref === 'rrhh' ? 
                                 <Link className="link" to={{pathname: "/rrhh" }}>
                                    <MenuItem 
                                        key={page.id} 
                                        component='span' 
                                    >
                                        <Typography fontSize="0.9rem" textAlign="center" sx={{ color: '#36a9df'}}>
                                            {page.nombre} 
                                        </Typography>
                                    </MenuItem> 
                                </Link>
                                :
                                <Link className="link" to={{pathname: "/", hash:`${page.ref}`}} state={page.ref}>
                                    <MenuItem 
                                        key={page.id} 
                                        component='span' 
                                    >
                                        <Typography fontSize="0.9rem" textAlign="center" sx={{ color: '#36a9df'}} >
                                            {page.nombre} 
                                        </Typography>
                                    </MenuItem> 
                                </Link>
                                }
                            </div>
                        )
                    })}
                    {/* <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' }, justifyContent: 'center' }}>
                        <Tooltip title="En construccion">
                            <Button color="info" width='100%' variant='outlined' >Acceso clientes</Button>
                        </Tooltip>
                    </Box> */}
                </Menu>

                <Typography
                    noWrap
                    component="div"
                    sx={{ justifyContent: 'right', display: { xs: 'flex', lg: 'none' } }}
                >
                    <img src={Logo} alt="logo" sx={{ justifyContent: 'space-between', }} />
                </Typography>
            </Box>

        </>
    )
}