import { useReducer } from "react";
import * as Yup from 'yup';

export const useForm = (initialValues, onSubmit) => {
    const [state, dispatch] = useReducer(formReducer, initialValues);

    function changeHandler(event, newEvent = null) {
        let { id, value, files } = event.target;
        let updatedElement = null

        const idState = newEvent?.parent?? id;
        const valueState = newEvent?.value?? newEvent?? value;
        
        if(id === 'archivos') {
            updatedElement = { ...state[idState] };     
            updatedElement.value = files[0];
            updatedElement.name = files[0]?.name;
        } else {
            updatedElement = { ...state[idState] };     
            updatedElement = { ...updatedElement, value: valueState };
        }      
        
        dispatch({idState, updatedElement})    
    };

    const errorHandler = (event, schema, newEvent=null) => {
        let { id, value } = event.target;

        const idState = newEvent?.parent?? id;
        const valueState = newEvent?.value?? newEvent?? value;
        
        Yup.reach(schema, idState, valueState)
        .validate(value)
        .then(r => {
            let updatedElement = {...state[idState], errors: { exists: false, message: []}}
            dispatch({idState, updatedElement})
        })
        .catch(err => {
            const errors = new Yup.ValidationError(err).errors;
            let updatedElement = {...state[idState], errors: { exists: true, message: errors}}
            dispatch({idState, updatedElement})
        })
    }

    const submitHandler = async (event, schema) => {
        event.preventDefault();
        const results = Object.keys(state).reduce((final, key) => {
            final[key] = state[key].value;
            return final;
        }, {});

        const valid = await schema.isValid(results, {
            abortEarly: false,
        })
       
        if(valid) {
            onSubmit(results)
        } else {
            Object.keys(results).forEach((key) => {
                Yup.reach(schema, key)
                    .validate(results[key])
                    .then()
                    .catch(err => {
                        const errors = new Yup.ValidationError(err).errors;
                        dispatch({idState: key, updatedElement: {...state[key], errors: { ...state[key].errors, exists: true, message: errors }}})
                    })
            })
        }
    }

    return { state, submitHandler, changeHandler, errorHandler }
}

function formReducer(prevState, {idState, updatedElement}) {
    return {...prevState, [idState]: updatedElement};
};