import { Box, Container, IconButton, Link, Modal, Typography } from '@mui/material'
import React from 'react';
import './Footer.css';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Year = new Date().getFullYear()

const Footer = () => {


    const Footerstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vh',
        bgcolor: '#fff',
        borderRadius: '10px',
        borderColor: 'transparent',
        p: 4
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>

            <Container
                maxWidth="100%"
                align="center"
                justify="center"
                direction="column"
                sx={{
                    paddingTop: '2em',
                    paddingBottom: '2em',
                    backgroundColor: '#373C46',
                    minHeight: '8vh',
                    maxHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "arround",
                    alignItems: "center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                }}
            >

                <Box
                    sx={{
                        width: '80%',
                        display: { xs: 'none', lg: 'flex' },
                        margin: 'auto'
                    }}>
                    <Container
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        <Typography align="left"
                            component="div"
                            sx={{ justifyContent: "left", maxWidth: '40%' }} >
                            <img src="logos/iso_blanco3.png" alt="logo" />
                        </Typography>

                        <Typography align="right"
                            component="div"
                            sx={{
                                justifyContent: 'right',
                                verticalAlign: 'middle',
                                maxWidth: '50%',
                                padding: '1em',
                                display: 'block'
                            }}
                        >
                            <Link color="inherit" underline="none" href='https://www.instagram.com/eiv.software/' target="_blank">  <IconButton><InstagramIcon /></IconButton></Link>
                            <Link color="inherit" underline="none" href='https://www.linkedin.com/company/eiv-software-srl/' target='_blank'>  <IconButton><LinkedInIcon /> </IconButton></Link>
                            <Link color="inherit" underline="none" href='https://www.facebook.com/EIV-Software-961324427269592' target='_blank'>  <IconButton><FacebookIcon /> </IconButton></Link>
                        </Typography>
                    </Container>
                </Box>

                <Box sx={{
                    width: "70%",
                    display: { xs: 'none', lg: 'flex' },
                    paddingTop: '1em'
                }}>
                    <Container
                        sx={{
                            alignSelf: "left",
                            paddingLeft: "4rem",
                            borderRight: "2px solid",
                            display: { xs: 'none', lg: 'flex' }
                        }}
                    >

                        <Typography component="div"
                            align="left"
                            variant="subtitle1"
                            sx={{ fontFamily: 'Montserrat', fontWeight: 'light', display: 'block' }}
                        >

                            <CallIcon /> ADMINISTRACIÓN: (0341) 2381179 <br />
                            <Link color="inherit" underline="none" target="_blank" href="https://wa.link/vh3pf6"><WhatsAppIcon /> SOPORTE WHATSAPP: (0341) 152-296137 </Link> <br />
                            <EmailIcon /> SOPORTE: soporte@eiva.com.ar <br />
                            <PersonPinCircleIcon /> OFICINAS: Corrientes 631, 5° C y D.<br />
                            <PersonPinCircleIcon />Rosario, Santa Fe, Argentina.

                        </Typography>

                    </Container>

                    <Container maxWidth="70%"
                        align="right"
                        sx={{
                            verticalAlign: "middle",
                            display: { xs: 'none', lg: 'flex' },
                            margin: 'auto'
                        }}>

                        <Typography
                            align="left"
                            variant="subtitle1"
                            sx={{
                                display: 'block',
                                fontFamily: 'Montserrat',
                                fontWeight: 'light',
                            }} >

                            <Link color="inherit" underline="none" href='#home' >HOME</Link><br />
                            <Link color="inherit" underline="none" href='#productos'>PRODUCTOS</Link><br />
                            <Link color="inherit" underline="none" href='#empresas' >EMPRESA</Link><br />
                        </Typography>
                        <Typography
                            align="left"
                            variant="subtitle1"
                            sx={{
                                display: 'block',
                                fontFamily: 'Montserrat',
                                fontWeight: 'light',
                                marginLeft: 'auto'
                            }} >

                            <Link color="inherit" underline="none" href='#clientes'  >CLIENTES</Link><br />
                            <Link color="inherit" underline="none" href='#contacto'>CONTACTO</Link><br />
                            <Link color="inherit" underline="none" href='rrhh'>TRABAJA CON NOSOTROS</Link><br />
                            <Link color="inherit" underline="none" onClick={handleOpen}>POLITICAS DE CALIDAD</Link><br />
                        </Typography>

                    </Container>

                </Box>
                {/* footer en mobile */}
                <Box
                    sx={{
                        width: '100%',
                        display: { xs: 'flex', lg: 'none' },
                        margin: 'auto'
                    }}>
                    <Container className='logosFooterMobile'
                        sx={{ display: 'inherit', alignItems: 'center', justifyContent: 'center', maxWidth: '90%' }}
                    >
                        <Typography align="left"
                            component="div"
                            sx={{ justifyContent: "left", maxWidth: '20%' }} >
                            <img src="logos/iso_blanco3.png" alt="logo" />
                        </Typography>

                        <Typography align="right"
                            component="div"
                            sx={{
                                justifyContent: 'right',
                                alignItems: 'center',
                                maxWidth: '80%',
                                padding: '1em',
                                display: 'flow'
                            }}
                            noWrap
                        >
                            <Link color="inherit" underline="none" href='https://www.instagram.com/eiv.software/' target="_blank">  <IconButton><InstagramIcon /></IconButton></Link>
                            <Link color="inherit" underline="none" href='https://www.linkedin.com/company/eiv-software-srl/' target='_blank'>  <IconButton><LinkedInIcon /> </IconButton></Link>
                            <Link color="inherit" underline="none" href='https://www.facebook.com/EIV-Software-961324427269592' target='_blank'>  <IconButton><FacebookIcon /> </IconButton></Link>
                        </Typography>
                    </Container>
                </Box>
                <Box sx={{
                    width: "80%",
                    display: { xs: 'flex', lg: 'none' },
                    paddingTop: '1em',
                    margin: 'auto'
                }}>
                    <Container
                        sx={{
                            alignSelf: "left",
                            paddingBottom: '1em',
                            borderBottom: "2px solid",
                            display: { xs: 'flex', lg: 'none' },
                            gap: '1em'
                        }}
                    >

                        <Typography component="div"
                            noWrap
                            align="left"
                            variant="subtitle1"
                            sx={{ fontFamily: 'Montserrat', fontWeight: 'light', display: 'block' }}
                        >

                            <CallIcon sx={{ marginRight: '10px' }} />(0341) 2381179 <br />
                            <Link color="inherit"
                                underline="none"
                                target="_blank"
                                href="https://wa.link/vh3pf6">
                                <WhatsAppIcon sx={{ marginRight: '10px' }} />(0341) 152-296137 </Link> <br />
                            <EmailIcon sx={{ marginRight: '10px' }} />soporte@eiva.com.ar <br />
                            <PersonPinCircleIcon sx={{ marginRight: '10px' }} />Corrientes 631, 5° C y D. <br />
                            <PersonPinCircleIcon sx={{ marginRight: '10px' }} />Rosario, Santa Fe, Argentina.

                        </Typography>

                    </Container>
                </Box>
                <Box sx={{
                    width: "80%",
                    display: { xs: 'flex', lg: 'none' },
                    paddingTop: '1em',
                    margin: 'auto'
                }}>


                    <Container maxWidth="90%"
                        align="right"
                        sx={{
                            verticalAlign: "middle",
                            display: { xs: 'flex', lg: 'none' },
                            margin: 'auto',
                            gap: '2em'

                        }}>

                        <Typography
                            align="left"
                            variant="subtitle1"
                            sx={{
                                display: 'block',
                                fontFamily: 'Montserrat',
                                fontWeight: 'light',

                            }} >

                            <Link color="inherit" underline="none" href='#home' >HOME</Link><br />
                            <Link color="inherit" underline="none" href='#productos'>PRODUCTOS</Link><br />
                            <Link color="inherit" underline="none" href='#empresas' >EMPRESA</Link><br />
                        </Typography>
                        <Typography
                            align="left"
                            variant="subtitle1"
                            sx={{
                                display: 'block',
                                fontFamily: 'Montserrat',
                                fontWeight: 'light',
                                marginLeft: 'auto',
                                gap: '1em'
                            }} >

                            <Link color="inherit" underline="none" href='#clientes'  >CLIENTES</Link><br />
                            <Link color="inherit" underline="none" href='#contacto'>CONTACTO</Link><br />
                            <Link color="inherit" underline="none" href='rrhh'>TRABAJA CON NOSOTROS</Link><br />
                            <Link color="inherit" underline="none" onClick={handleOpen}>POLITICAS DE CALIDAD</Link><br />
                        </Typography>
                    </Container>

                </Box>
            </Container>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-politicas-title"
                    aria-describedby="modal-politicas-description"
                    disableEnforceFocus={false}
                >
                    <Box sx={Footerstyle}>
                        <Typography id="modal-politicas-title" variant="h6" component="h2"
                            sx={{ }}>
                            <b>Políticas de Calidad</b>
                        </Typography>
                        <Typography id="modal-politicas-description" sx={{ mt: 2 }}>
                            <b>EIV Software S.R.L. </b> es una empresa de Desarrollo de Software orientada a Entidades Financieras, de Salud y Comerciales,
                            comprometida en satisfacer las necesidades informáticas de los clientes con alto nivel de innovación y calidad,
                            cumpliendo con las normas ISO 9001, legales y reglamentarias aplicables, basándonos en las siguientes <b>VENTAJAS COMPETITIVAS:</b>
                            <ul>
                                <li><b>PRODUCTIVIDAD y EFICIENCIA:</b> Potenciando a las empresas con nuestro sistema de gestión y toma de decisiones.</li>
                                <li><b>EXPERIENCIA Y CAPACIDAD TECNICA:</b> Más de 30 años de trayectoria en el rubro financiero nos avalan.
                                    Contamos con un grupo variado de profesionales en constante perfeccionamiento para brindar a nuestros clientes un enfoque multidisciplinarios de las situaciones que se presentan
                                    desarrollando soluciones para las diversas áreas de negocio.</li>
                                <li><b>ORGANIZACIÓN y COMPROMISO:</b> Trabajamos en equipo para agilizar las exigencias del mercado y de nuestros clientes, intentando tener una comunicación clara de cada una de las necesidades y aprendiendo de los errores cometidos para lograr la mejora continua.</li>
                            </ul>
                        </Typography>
                    </Box>
                </Modal>
            </div>
            <Container
                maxWidth="100%"
                align="center"
                justify="center"
                direction="column"
                sx={{
                    position: 'fixed-bottom',
                    heigth: '5vh',
                    maxHeight: '10vh',
                    backgroundColor: '#2E3239',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "arround",
                    verticalAlign: 'middle',
                    alignItems: "center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    padding: '0.5rem'
                }}
            >
                © {Year} EIV Software | Todos los derechos reservados.
            </Container>
        </>
    )
}

export default Footer
