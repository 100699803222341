import { useState } from 'react';
import axios from 'axios';

let config = window['siteConfig'];
axios.defaults.baseURL = config.apiUrl;

export const useAxios = () => {
    const [response, setResponse] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchData = async (method, url, headers, body) => {
        let data;

        Object.keys(headers).forEach((key) => {
            if(headers[key] === 'multipart/form-data') {
                data = new FormData();  
                Object.keys(body).forEach((key) => {
                    data.append(key, body[key])
                })
            } else {
                data = body
            }

            axios.defaults.headers[key] = headers[key]
        })       

        try {
            const res = await axios[method](url, data);
            setResponse(res);
        } catch(err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }   

    return { fetchData, response, error, loading }
}