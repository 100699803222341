import Container from '@mui/material/Container';

export const ComponentContainer = ({bgColor, bgImg, children, justify, paddingTop}) => {
    return (
        <Container
            maxWidth="100%" 
            align="center"
            justify={'center'}
            direction="column"
            sx={{
                paddingTop: `${paddingTop?? '4em'}`,
                paddingBottom: '4em',
                backgroundColor: bgColor,
                minHeight: "100vh",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: `${justify?? 'center'}`,
                alignItems: "center",
                backgroundImage: `url(${bgImg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                zIndex: 2,
            }} 
        >
            {children}
        </Container>
    )
}
