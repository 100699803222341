import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./animate.css"
import Pages from './pages/pages';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Rrhh from './pages/rrhh/Rrhh';
import { ThemeProvider } from '@mui/material';
import Barra from './components/barra/Barra';
import theme from './theme/theme';

function App() {
  return (
      <div className="App">
        <Router>
          <Barra />
          <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/" element={<Pages />}>
                </Route>
                <Route path="/rrhh" element={<Rrhh />}/>
            </Routes>
          </ThemeProvider>
        </Router>
      </div>    
  );
}

export default App;



