import { TextField, Typography } from "@mui/material"
import { useForm } from "../../../../hooks/useForm"
import { Box } from "@mui/system"
import { ButtonComponent } from "../../../button/ButtonComponent"
import { useAxios } from "../../../../hooks/useAxios"
import { Fragment, useState } from "react"
import { Captcha } from "../../../captcha/Captcha"
import { AlertComponent } from "../../../alert/Alert"
import './FormComponent.css'
import { validationSchema } from "./validationCurriculum"

export const FormComponent = ({ initialForm }) => {
    const { fetchData, response, error } = useAxios()
    const [captcha, setCaptcha] = useState(null);
    const headers = {
        'captcha-response': captcha,
        'Content-Type': 'multipart/form-data'
    }
    
    const { state, submitHandler, changeHandler, errorHandler } = useForm(initialForm, (values) => fetchData('post', '/curriculum', headers, values))

    return (
        <Box component="form" onSubmit={(event) => submitHandler(event, validationSchema)} sx={{ width: { sm: '100%' }, display: 'grid', flexWrap: 'wrap' }}>
            {
                Object.keys(state).map((key) => {
                    if (key !== 'archivos') {
                        return (
                            <Fragment key={key}>
                                <TextField
                                    key={key}
                                    id={key}
                                    onChange={(event) => changeHandler(event)}
                                    value={state[key].value}
                                    label={state[key].label}
                                    error={state[key].errors.exists}
                                    onBlur={(event) => errorHandler(event, validationSchema)}
                                    required={state[key].required}
                                    type={state[key].type}
                                    multiline={state[key].multiline}
                                    inputProps={state[key].inputProps}
                                    variant='filled'
                                    color="secondary"
                                >
                                    
                                </TextField>
                                <p className="error">{state[key].errors.exists ? state[key].errors.message.map((error, i) => <span key={i}>{error}</span>) : null}</p>
                            </Fragment>
                            )
                    } else {
                         return (
                            <div className="input-file-field" key={key}>
                                <TextField
                                    sx={{ position: "relative" }}
                                    key={key}
                                    id={key}
                                    onChange={(event) => changeHandler(event)}
                                    label={state[key].label}
                                    error={state[key].errors.exists}
                                    onBlur={(event) => errorHandler(event, validationSchema)}
                                    required={state[key].required}
                                    type={state[key].type}
                                    variant='filled'
                                    inputProps={{"accept": ".pdf, .doc"}}
                                >
                                    
                                </TextField>
                                <div className="input-file-container">
                                    <label className="input-file-label" htmlFor="archivos">ADJUNTÁ TU CV</label>
                                    <Box
                                        sx={{
                                            overflow: 'hidden',
                                            width: '100%',
                                        }}>
                                        <Typography 
                                            width={'100%'}
                                            variant='subtitle2' 
                                            sx={{ 
                                                overflow: 'hidden', 
                                                whiteSpace: 'nowrap', 
                                                justifyContent: 'center', 
                                                color: '#fff', 
                                                padding: 0, 
                                                margin: 0,
                                                textOverflow: 'ellipsis' }}>

                                            {state.archivos.name ? state.archivos.name : "Seleccione un archivo"}
                                        </Typography>
                                    </Box>
                                </div>
                                <p className="error">{state[key].errors.exists ? state[key].errors.message.map((error, i) => <span key={i}>{error}</span>) : null}</p>
                            </div>
                        )
                    }
                })
            }
            <Box 
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <AlertComponent response={response} error={error}/>
                <Captcha setCaptcha={setCaptcha} />
                <Box
                    sx={{
                        justifyContent: 'space-around',
                        p: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'end'
                }}>
                    <ButtonComponent type="submit" htmlFor="send-cv" icon="nav" text="Enviar formulario" />
                </Box>
            </Box>
        </Box>
    )
}