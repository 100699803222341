import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '5px'
          },
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(225,225,225, 0.3)",
            borderRadius: "10px"
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent"
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: '#FFFFFF'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root : {
              color: '#36a9df',
              ":hover": {
                color: '#01404F',
                backgroundColor: '#DFF5FF'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF'
          }
        }
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: '#FFFFFF'
          }
        }
      },
      MuiAutocomplete: {
          styleOverrides: {
              listbox: {
                  backgroundColor:'#373c46',
              },
          }
      },
      MuiFab: {
          styleOverrides: {
              root: {
                  ":hover": {
                      color: 'black'
                  }
              }
          }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "white",
          },
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderColor: "white",
            ":after": {
              borderBottom: "1px solid white"
            },
            ":before": {
              borderBottom: "1px solid white"
            }
          },
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderColor: "white",
            ":after": {
              borderBottom: "1px solid white"
            },
            ":before": {
              borderBottom: "1px solid white"
            }
          },
          input: {
          }
        }
      },
    },
    palette: {
      type: 'light',
      primary: {
        main: '#36a9df',
      },
      secondary: {
        main: '#FFFFFF',
      },
      background: {
        paper: '#373c46',
        default: '#969494',
      },
      text: {
        primary: '#ffffff',
        secondary: '#000000',
      },
    },
  });

theme.typography.h4 = {
    fontSize: '2rem',
    '@media(max-width: 640px)': {
      fontSize: '1.75rem'
    }
}

theme.typography.h6 = {
    fontSize: '1.4rem',
    '@media(max-width: 640px)': {
      fontSize: '1rem'
    }
}


export default theme