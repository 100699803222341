import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import "./pages.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CircularProgress } from '@mui/material';

const Home = lazy(() => import("./home/Home"));
const Empresa = lazy(() => import("./empresa/Empresa"));
const Contacto = lazy(() => import("./contacto/Contacto"));
const Clientes = lazy(() => import("./clientes/Clientes"));
const Producto = lazy(() => import("./producto/Producto"));

export default function Pages() {
  const { state } = useLocation()
  
  useEffect(() => {
    if(state) {
      let id = state;
      let element = document.getElementById(id)
      element.scrollIntoView({behavior: 'smooth'})
    }
  }, [state])

  const routes = [ 
    { Component: Home, name: 'home', id: 1 },
    { Component: Producto, name: 'productos', id: 2},
    { Component: Empresa, name: 'empresas', id: 3},
    { Component: Clientes, name: 'clientes', id: 4},
    { Component: Contacto, name: 'contacto', id: 5},
    { Component: Footer, name: 'footer', id: 6}
  ]  

  return (
    <>
    <HelmetProvider>
      <Helmet>
      <meta charSet='utf-8' />
        <title>EIV Software</title>
        {/*
        <link rel="canonical" href="https://eiva.com.ar/" />
        <link rel="canonical" href="https://eivsofware.com.ar/" />
        <link rel="canonical" href="https://eivsofware.com/" />
        */}
        <meta name="description" content="EIV Software es una empresa de Desarrollo de Software orientada a entidades financieras, de salud y comerciales comprometida con la excelencia para satisfacer necesidades informáticas con alto nivel de innovación y calidad hacia nuestros clientes."/>
        <meta property='og:type' content="website" />
        <meta property='og:title' content="EIV Software | RRHH" />
        <meta property='og:url' content="https://eiva.com.ar/" />
        <script type="application/ld+json">
       { ` 
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "image": [
            "https://example.com/photos/1x1/photo.jpg",
            "https://example.com/photos/4x3/photo.jpg",
            "https://example.com/photos/16x9/photo.jpg"
          ],
          "name": "EIV Software",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "631 Corrientes",
            "addressLocality": "Rosario",
            "addressRegion": "Santa Fe",
            "postalCode": "2000",
            "addressCountry": "AR"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": -32.94353922163283,
            "longitude": -60.641632730733875
          },
          "url": "https://eiva.com.ar",
          "telephone": "+54 9 341 5284551",
          "openingHoursSpecification": [
        	{
          	"@type": "OpeningHoursSpecification",
          	"dayOfWeek": [
            	"Monday",
            	"Tuesday"
          	],
          	"opens": "08:00",
          	"closes": "17:00"
        	}
         ]
        }
        `}
      </script>
      </Helmet>
    </HelmetProvider>
      { routes.map(({Component, name, id}) => (
        <Fragment key={id}>
          <Suspense fallback={<div className='loading'> <CircularProgress /></div>}>
            <div name={name} id={name}></div>
            <Component/>
          </Suspense>
        </Fragment>
      ))}
    </>
  )  
}